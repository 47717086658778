import './App.css';
import logo from './logo192.webp';

function isiOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const outnaboutURL = "outnabout:/" + window.location.pathname + window.location.search
window.location = outnaboutURL;

function App() {
  console.log(navigator.userAgent)
  console.log(navigator.userAgent.split("(")[1].split(";")[0])

  return (
    <div className="App">
      <header className="App-header" style={{ gap: 20 }}>

        <h1 className='ona-dark ona-heading'>
          <p className='ona-p'>
            Let's Get
          </p>
          <p className='ona-p'>
            Out N About!
          </p>
        </h1>
        <img src={logo} className="App-logo" alt="logo" />

        <button className='ona-button sans-pro' onClick={() => {
          const outnaboutURL = "outnabout:/" + window.location.pathname + window.location.search
          window.location = outnaboutURL;
          setTimeout(() => {
            window.location = isiOS() ? "https://itunes.apple.com/app/id6451323776" : "https://play.google.com/store/apps/details?id=net.alphacipher.outnabout";
          }, 500);
        }}>
          Let's Go
        </button>
      </header>
    </div>
  );
}

export default App;
